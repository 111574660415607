import { useEffect, useRef, useState } from "react";
import { TMessageStatus, TProcessStates } from "../../lib/types/general";
import { TUserTypes } from "../../lib/types/users";
import { useMessages } from "../../services/notifications";
import { Spinner } from "../sections/backoffice/common/spinner";

export const InviteButton = ({
    userToInviteId,
    userToInviteInvitationState,
    userType,
}: {
    userToInviteId: string;
    userToInviteInvitationState?: TMessageStatus;
    userType: TUserTypes;
}) => {
    const { sendInvitation, sendInviteState } = useMessages(userToInviteId);
    const isDone: TProcessStates | undefined = userToInviteInvitationState
        ? "done"
        : undefined;
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [currentState, setCurrentState] = useState<TProcessStates>("idle");
    const [successMessage, setSuccessMessage] = useState("");
    const refNote = useRef<HTMLTextAreaElement>(null);
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const refModal1 = useRef<HTMLDivElement>(null);
    const refModal2 = useRef<HTMLDivElement>(null);
    const windowGlobal = window as any;

    const handleFirstModalShow = () => {
        setShowFirstModal(true);

        const showModal1 = () => {
            if (!refModal1.current) {
                setTimeout(() => {
                    showModal1();
                }, 100);
                return;
            }

            windowGlobal.wwg_refModal1 = new window.bootstrap.Modal(
                refModal1.current
            );

            if (windowGlobal.wwg_refModal1) {
                windowGlobal.wwg_refModal1.show();
            }
        };

        showModal1();
    };
    const handleFirstModalClose = () => {
        if (!refModal1.current) {
            setTimeout(() => {
                handleFirstModalClose();
            }, 100);
            return;
        }
        if (windowGlobal.wwg_refModal1) {
            //TODO: change to window.bootstrap.Modal.getInstance(refModal1.current)?.hide(); when we update to bootstrap 5.2
            windowGlobal.wwg_refModal1.hide();
        }
        setShowFirstModal(false);
    };
    const handleSecondModalShow = () => {
        setShowSecondModal(true);
        const showModal2 = () => {
            if (!refModal2.current) {
                setTimeout(() => {
                    showModal2();
                }, 100);
                return;
            }

            windowGlobal.wwg_refModal2 = new window.bootstrap.Modal(
                refModal2.current
            );
            if (windowGlobal.wwg_refModal2) {
                windowGlobal.wwg_refModal2.show();
            }
        };

        showModal2();
    };
    const handleSecondModalClose = () => {
        if (!refModal2.current) {
            setTimeout(() => {
                handleSecondModalClose();
            }, 100);
            return;
        }
        if (windowGlobal.wwg_refModal2) {
            //TODO: change to window.bootstrap.Modal.getInstance(refModal2.current)?.hide(); when we update to bootstrap 5.2
            windowGlobal.wwg_refModal2.hide();
        }
        setShowSecondModal(false);
    };
    const noNoteHandler = () => {
        sendInvitation();
        handleFirstModalClose();
    };

    const onChangeHandler = (val?: string) => {
        setButtonDisabled(!!!val);
    };

    const inviteWithNoteClickHandler = async () => {
        if (!refNote) return;
        if (!refNote.current?.value) return;
        setCurrentState("working");
        setButtonDisabled(true);
        try {
            const note = await sendInvitation(refNote.current.value);

            if (note === "ok") {
                setSuccessMessage("Your invitation has been sent!");
                setCurrentState("done");
            }
        } catch {
            setSuccessMessage("Something went wrong, please try again later!");
        } finally {
            setCurrentState("idle");
            setButtonDisabled(false);
        }
    };

    useEffect(() => {
        return () => {
            if (windowGlobal.wwg_refModal1) {
                windowGlobal.wwg_refModal1.hide();
            }
            if (windowGlobal.wwg_refModal2) {
                windowGlobal.wwg_refModal2.hide();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <button
                className={
                    (isDone || sendInviteState) === "done"
                        ? "btn btn-outline-dark w-100 rounded-3"
                        : "btn btn-primary w-100 rounded-3 fw-semibold"
                }
                data-testid="invite-button"
                disabled={(isDone || sendInviteState) !== "idle"}
                data-toggle="modal"
                onClick={() => handleFirstModalShow()}
            >
                {(isDone || sendInviteState) === "idle" &&
                    userType === "lab" && (
                        <>
                            <i className="fa-solid fa-user-plus pe-2"></i>{" "}
                            CONTACT
                        </>
                    )}
                {(isDone || sendInviteState) === "idle" &&
                    userType === "tech" && (
                        <>
                            <i className="fa-solid fa-user-plus pe-2"></i>{" "}
                            INVITE
                        </>
                    )}

                {(isDone || sendInviteState) === "error" && (
                    <>
                        <i
                            data-testid="error"
                            className="fa-solid fa-circle-exclamation"
                        ></i>{" "}
                        Something went wrong. Please try again.
                    </>
                )}

                {(isDone || sendInviteState) === "working" && (
                    <>
                        <i data-testid="loading-spinner" className="text-white">
                            Sending ... {""} <Spinner />{" "}
                        </i>
                    </>
                )}

                {(isDone || sendInviteState) === "done" &&
                    userType === "lab" && (
                        <small>
                            Contact request sent. <br /> The lab has been
                            notified.
                        </small>
                    )}
                {(isDone || sendInviteState) === "done" &&
                    userType === "tech" && (
                        <small>
                            Invitation sent. <br /> The tech has been notified.
                        </small>
                    )}
            </button>

            {showFirstModal && (
                <div className="modal fade" ref={refModal1} tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-2">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold">
                                    Add a note to your invitation?
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    data-bs-dismiss="modal"
                                    onClick={handleFirstModalClose}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Personalize your invitation by adding a
                                    note. <br />
                                    WinWinGo members are more likely to accept
                                    invitations that include a note.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        handleFirstModalClose();
                                        handleSecondModalShow();
                                    }}
                                >
                                    Add a note
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={noNoteHandler}
                                >
                                    Send without a note
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSecondModal && (
                <div className="modal fade" ref={refModal2} tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-2">
                            <div className="modal-header">
                                <h1
                                    className="modal-title fs-5 fw-bold"
                                    id="inviteModalLAddMessageLabel2"
                                >
                                    Add a note to your invitation
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleSecondModalClose}
                                ></button>
                            </div>
                            <div className="modal-body">
                                {(isDone || sendInviteState) !== "done" ? (
                                    <>
                                        <form>
                                            <div className="mb-3">
                                                <textarea
                                                    ref={refNote}
                                                    className="form-control form-control-sm rounded-0"
                                                    id="message-text"
                                                    placeholder="Write your note..."
                                                    maxLength={200}
                                                    style={{ height: "150px" }}
                                                    onChange={(e) =>
                                                        onChangeHandler(
                                                            e.target.value
                                                        )
                                                    }
                                                ></textarea>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-secondary text-center">
                                            {successMessage}
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                {(isDone || sendInviteState) !== "done" ? (
                                    <>
                                        <button
                                            className="btn btn-outline-primary"
                                            data-bs-dismiss="modal"
                                            onClick={handleSecondModalClose}
                                        >
                                            Cancel{" "}
                                        </button>
                                        <button
                                            className="btn btn-secondary px-4"
                                            disabled={buttonDisabled}
                                            onClick={inviteWithNoteClickHandler}
                                        >
                                            {currentState === "working" ? (
                                                <Spinner />
                                            ) : (
                                                " Send"
                                            )}
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
