import { createContext, useContext, useEffect, useState } from "react";
import { TInviteMessage, TMessage } from "../lib/types/general";
import { useAuthContext } from "./auth-context";
import {
    acceptInvitation,
    rejectInvitation,
    getNotifications,
    setMessageRead,
} from "../services/notifications";
import { useServiceContext } from "./service";
import { useLocation } from "react-router-dom";
import { noop } from "firebase-mock/src/lodash";

export type TAcceptRejectInvitationClickHandler = (
    fromUserID: string
) => Promise<void>;
export type TSetAsReadMessageClickHandler = (id: number) => Promise<void>;
export type TActions = "refreshContactList" | "none" | "other";

export type TUseNotifications = {
    notificationsNumber: number;
    invitationsList: TInviteMessage[];
    messagesList: TMessage[];
    setActionsHandler: (p: TActions, fn?: () => void) => void;
    acceptInvitationClickHandler: TAcceptRejectInvitationClickHandler;
    rejectInvitationClickHandler: TAcceptRejectInvitationClickHandler;
    setMessageReadClickHandler: TSetAsReadMessageClickHandler;
};

export const useNotifications = (): TUseNotifications => {
    const [actionsHandler, _setActionsHandler] = useState<
        Record<TActions, () => void>
    >({
        none: noop,
        other: noop,
        refreshContactList: noop,
    });

    const { getUserTokenId } = useAuthContext();
    const {
        notifications: { addNewMessagesHandler, removeMessageHandler },
    } = useServiceContext();
    const [notificationsNumber, setNotificationsNumber] = useState<number>(0);
    const [messagesList, setMessagesList] = useState<TMessage[]>([]);
    const [invitationsList, setInvitationsList] = useState<TInviteMessage[]>(
        []
    );

    const checkMessages = async () => {
        const userTokenId = await getUserTokenId();
        if (!userTokenId) {
            setTimeout(() => {
                checkMessages();
            }, 5000);
            return;
        }
        const messages = await getNotifications(userTokenId);
        let messagesCount = 0;
        if (messages.data.invitations) {
            setInvitationsList(messages.data.invitations);
            messagesCount += messages.data.invitations.length;
        }

        if (messages.data.messages) {
            messagesCount += messages.data.messages.length;
            if (messages.data.messages && messages.data.messages.length > 0) {
                messages.data.messages.forEach((m) => {
                    if (m.action_data) {
                        m.isFromChats =
                            m.action_data.goto &&
                            m.action_data.goto === "showConversation"
                                ? true
                                : false;
                    }
                });
            }
            setMessagesList(messages.data.messages);
        }

        setNotificationsNumber(messagesCount);
        /*   setTimeout(async () => {
               await checkMessages();
           }, 30000);*/
    };

    const acceptInvitationClickHandler: TAcceptRejectInvitationClickHandler =
        async (fromUserId: string) => {
            const invitation = invitationsList.find(
                (msg) => msg.from === fromUserId
            );
            if (!invitation) return;
            try {
                debugger;
                await acceptInvitation(await getUserTokenId(), fromUserId);
                const newList = invitationsList.filter(
                    (msg) => msg.from !== fromUserId
                );
                setInvitationsList(newList);
                setNotificationsNumber((old) => old - 1);
                actionsHandler.refreshContactList();
            } finally {
            }
        };

    const rejectInvitationClickHandler: TAcceptRejectInvitationClickHandler =
        async (fromUserId: string) => {
            const invitation = invitationsList.find(
                (msg) => msg.from === fromUserId
            );
            if (!invitation) return;
            try {
                await rejectInvitation(await getUserTokenId(), fromUserId);
                const newList = invitationsList.filter(
                    (msg) => msg.from !== fromUserId
                );
                setInvitationsList(newList);
                setNotificationsNumber((old) => old - 1);
            } finally {
            }
        };

    const setActionsHandler = (action: TActions, handler?: () => void) => {
        _setActionsHandler((prev) => {
            prev[action] = handler ?? noop;
            return { ...prev };
        });
    };

    const setMessageReadClickHandler: TSetAsReadMessageClickHandler = async (
        id: number
    ) => {
        const message = messagesList.find((msg) => msg.id === id);
        if (!message) return;
        try {
            if (!message.isFromChats) {
                await setMessageRead(message, await getUserTokenId());
            }
            const newList = messagesList.filter((msg) => msg.id !== id);
            setMessagesList(newList);
            setNotificationsNumber((old) => old - 1);
        } finally {
        }
    };

    useEffect(() => {
        addNewMessagesHandler(checkMessages);

        return () => {
            removeMessageHandler(checkMessages);
        };
        // eslint-disable-next-line
    }, []);

    const locationInfo = useLocation();
    useEffect(() => {
        checkMessages();
        // eslint-disable-next-line
    }, [locationInfo.pathname]);

    return {
        notificationsNumber,
        invitationsList,
        messagesList,
        setActionsHandler,
        acceptInvitationClickHandler,
        rejectInvitationClickHandler,
        setMessageReadClickHandler,
    };
};

const NotificationContext = createContext<TUseNotifications>({
    invitationsList: [],
    messagesList: [],
    notificationsNumber: 0,
    setActionsHandler: (p: TActions, h?: () => void) => {},
    acceptInvitationClickHandler: async () => {},
    rejectInvitationClickHandler: async () => {},
    setMessageReadClickHandler: async () => {},
});

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = (props: any) => {
    const value: TUseNotifications = useNotifications();

    return (
        <NotificationContext.Provider value={value}>
            {props.children}
        </NotificationContext.Provider>
    );
};
