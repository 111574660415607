import { useEffect, useRef } from "react";
import { SUPPORT_EMAIL } from "../../lib/common/constants";

export const WelcomeModal = () => {
    const refWelcomeModal = useRef<HTMLDivElement>(null);
    const windowGlobal = window as any;
    const handleWelcomeModalClose = () => {
        if (!refWelcomeModal.current) {
            setTimeout(() => {
                handleWelcomeModalClose();
            }, 100);
            return;
        }
        if (windowGlobal.wwg_refWelcomeModal) {
            //TODO: change to window.bootstrap.Modal.getInstance(refWelcomeModal.current)?.hide(); when we update to bootstrap 5.2
            windowGlobal.wwg_refWelcomeModal.hide();
        }
    };

    useEffect(() => {
        if (!refWelcomeModal.current) return;

        windowGlobal.wwg_refWelcomeModal = new window.bootstrap.Modal(
            refWelcomeModal.current
        );

        windowGlobal.wwg_refWelcomeModal.show();

        return () => {
            if (windowGlobal.wwg_refWelcomeModal) {
                windowGlobal.wwg_refWelcomeModal.hide();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal fade" tabIndex={-1} ref={refWelcomeModal}>
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content bg-dark text-white rounded mx-3">
                    <div className="modal-header justify-content-center border-0">
                        <h1 className="modal-title highlighted-title">
                            Welcome Aboard!
                        </h1>
                        {/* <button type="button" className="btn-close btn-close-white border border-light border-3" data-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className="modal-body text-center border-0">
                        <h4>Let’s start your next chapter!</h4>
                        <br />
                        <h5 className="lh-base px-4">
                            {" "}
                            Complete your profile to get the most out of our
                            platform. This will help us match you with the best
                            labs!
                        </h5>
                    </div>
                    <div className="modal-footer border-0">
                        <button
                            type="button"
                            className="btn btn-info w-100"
                            // data-bs-dismiss="modal"
                            onClick={handleWelcomeModalClose}
                        >
                            Let's Go!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const WelcomeLabModalFreeBetaVersion = () => {
    const refWelcomeLabModalFreeBetaVersion = useRef<HTMLDivElement>(null);
    const windowGlobal = window as any;
    const handleWelcomeLabModalFreeBetaVersionClose = () => {
        if (!refWelcomeLabModalFreeBetaVersion.current) {
            setTimeout(() => {
                handleWelcomeLabModalFreeBetaVersionClose();
            }, 100);
            return;
        }
        if (windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion) {
            //TODO: change to window.bootstrap.Modal.getInstance(refWelcomeLabModalFreeBetaVersion.current)?.hide(); when we update to bootstrap 5.2
            windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion.hide();
        }
    };

    useEffect(() => {
        if (!refWelcomeLabModalFreeBetaVersion.current) return;

        windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion =
            new window.bootstrap.Modal(
                refWelcomeLabModalFreeBetaVersion.current
            );

        windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion.show();

        return () => {
            if (windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion) {
                windowGlobal.wwg_refWelcomeLabModalFreeBetaVersion.hide();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="modal fade"
            tabIndex={-1}
            ref={refWelcomeLabModalFreeBetaVersion}
        >
            <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                <div className="modal-content bg-dark text-white rounded p-3 text-center">
                    <div className="modal-header justify-content-center border-0">
                        <h2 className="modal-title highlighted-title">
                            Welcome to the Beta Version of WinWinGo!
                        </h2>
                    </div>
                    <div className="modal-body">
                        <h3 className=" ">
                            ⭐ FREE ACCESS ⭐
                            <br />
                            FOR LIMITED TIME ONLY
                        </h3>
                        <br />
                        <p className="lh-base">
                            Take a moment to personalize your profile and start
                            connecting with top dental techs.
                            <br />
                        </p>
                        <p className="mt-3 lh-base">
                            Thank you for joining us during our
                            <br />
                            <strong> BETA development stage! </strong>
                            <br /> <br />
                            If you spot an issue or have ideas for improvement,
                            we’d love to hear from you at{" "}
                            <a
                                className="btn-link text-primary"
                                href={"mailto:" + SUPPORT_EMAIL}
                            >
                                {" "}
                                {SUPPORT_EMAIL}
                            </a>
                            .
                            <br /> <br />
                            Your feedback helps us build the best dental
                            employment platform on the web!
                        </p>
                    </div>
                    <div className="modal-footer border-0">
                        <button
                            type="button"
                            className="btn btn-primary w-100 fw-bold"
                            // data-bs-dismiss="modal"
                            onClick={handleWelcomeLabModalFreeBetaVersionClose}
                        >
                            Go to My Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
