import { useState } from "react";
import { useRouteNavigation } from "../../lib/common/routes";
import { Header } from "../common/header";
import { Footer } from "./main-page/footer";

const techBenefits = [
    "Unlimited job opportunities",
    "Access to all lab postings",
    "Direct applications to top dental labs",
    "AI-Powered Job Suggestions based on hobbies, interests, and skills",
];

const labBenefits = [
    {
        title: "Easy to Use Dashboard",
        description:
            "Manage your job postings, applications, and communications all in one intuitive platform.",
    },
    {
        title: "Direct Communication with Techs",
        description:
            "Message technicians directly to streamline the hiring process and get the right talent faster.",
    },
    {
        title: "AI Tech/Lab Matching Feature",
        description:
            "Get AI-powered suggestions to match your open positions with the most suitable candidates.",
    },
    {
        title: "Target Techs Interested in Your Area",
        description:
            "Focus on attracting technicians interested in relocating or already living in your area.",
    },
    {
        title: "Dental Industry-Specific Features",
        description:
            "Our platform is built with the dental industry in mind, ensuring you find the right techs with the right skills.",
    },
];

export const Pricing = () => {
    const [isLabSelected, setIsLabSelected] = useState(true);
    const { navigateRouteClickHandler } = useRouteNavigation();

    // Toggle between Techs and Labs
    const toggleSelection = (isLab: boolean) => {
        setIsLabSelected(isLab);
    };

    return (
        <>
            <Header title="" />

            <div
                className="container-fluid bg-dark text-white pt-3"
                style={{ paddingBottom: "10rem" }}
            >
                <div className="container text-center">
                    <h1>Pricing</h1>
                    <h5 className="text-center mt-3 mb-4 mx-md-5 lh-base">
                        Whether you're a dental technician seeking new
                        opportunities or <br /> a lab in search of top talent,
                        choose the option that best matches your needs.
                    </h5>
                    <h6>
                        Click <strong className="text-info">Techs</strong> if
                        you're looking for job opportunities or <br />
                        <strong className="text-info">Labs</strong> if you're
                        ready to connect with skilled technicians.
                    </h6>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <div
                                className="btn-group my-5 pricing-toggle"
                                role="group"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-lg rounded-start border border-2 border-light px-5 ${
                                        !isLabSelected ? "btn-info" : "btn-dark"
                                    }`}
                                    onClick={() => toggleSelection(false)}
                                >
                                    Techs
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-lg rounded-end border border-2 border-light px-5 ${
                                        isLabSelected ? "btn-info" : "btn-dark"
                                    }`}
                                    onClick={() => toggleSelection(true)}
                                >
                                    Labs
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-4 text-start px-2 py-4 p-md-4">
                        <div className="col-lg-10">
                            <div className="highlighted-title">
                                <h1>
                                    {" "}
                                    {isLabSelected
                                        ? "Labs, Get Early Access—Free During Beta!"
                                        : "Techs Are Free!"}
                                </h1>
                            </div>
                            <p className="lh-lg">
                                {isLabSelected ? (
                                    <>
                                        For a limited time, we’re offering free
                                        beta access to our premium lab features.
                                        This is your chance to experience the
                                        full platform at no cost while we refine
                                        and improve with your feedback.
                                        <br />
                                        <strong>
                                            👉 Regular pricing: $299/month after
                                            beta.
                                        </strong>
                                        {/* For labs seeking skilled talent, our
                                        platform offers premium features to help
                                        you find the perfect fit faster. Our
                                        pricing is simple and transparent:
                                        $299/month with the first month free. */}
                                        <p>Enjoy full access to:</p>
                                        <ul className="list my-4 ">
                                            {labBenefits.map(
                                                (benefit, index) => (
                                                    <li key={index}>
                                                        <h6>{benefit.title}</h6>
                                                        <p>
                                                            {
                                                                benefit.description
                                                            }
                                                        </p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        At WinWinGo, we believe that dental
                                        technicians should have access to
                                        opportunities without paying a dime.
                                        <br />
                                        That’s why Techs are always free on our
                                        platform.
                                        <br />
                                        Whether you're a seasoned expert or just
                                        starting, you can explore jobs, connect
                                        with dental labs, and apply for
                                        positions with no fees attached.
                                        <p>Enjoy full access to:</p>
                                        <ul className="list my-4">
                                            {techBenefits.map(
                                                (benefit, index) => (
                                                    <li key={index}>
                                                        {benefit}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div
                                className="card text-center shadow pricing-toggle position-relative bg-light"
                                style={{ top: "-40%" }}
                            >
                                <div>
                                    <h4 className="text-end mt-3">
                                        {" "}
                                        <span className="badge rounded-0 rounded-start text-wrap ms-5 lh-base text-bg-warning shadow">
                                            {isLabSelected
                                                ? "🚀 Beta Access: Free for limited time only!"
                                                : ""}
                                        </span>
                                    </h4>
                                </div>
                                <div className="mx-4 mb-4">
                                    <p className="mt-3 highlighted-title fw-bold">
                                        {isLabSelected
                                            ? "Lab Subscription"
                                            : "Tech Subscription"}
                                    </p>
                                    <h1 className="my-2">
                                        <strong>
                                            {isLabSelected ? "$0" : "$0"}
                                        </strong>
                                        <strong style={{ fontSize: "small" }}>
                                            {" "}
                                            USD /month
                                        </strong>
                                    </h1>
                                    <p
                                        className="my-3"
                                        style={{ fontSize: "x-small" }}
                                    >
                                        {isLabSelected
                                            ? " Regular Price: $299 USD/month"
                                            : ""}
                                    </p>
                                    <button
                                        className="btn btn-info my-3 shadow fw-bold w-75"
                                        onClick={(e) =>
                                            navigateRouteClickHandler(
                                                isLabSelected
                                                    ? "signupLab"
                                                    : "signupTech",
                                                e
                                            )
                                        }
                                    >
                                        {isLabSelected
                                            ? "Get Beta Access Now"
                                            : "Get Started!"}
                                    </button>
                                    <p
                                        className="my-3"
                                        style={{ fontSize: "x-small" }}
                                    >
                                        {isLabSelected
                                            ? " Invoices and receipts available for easy company reimbursement"
                                            : ""}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
