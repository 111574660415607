// TODO: Uncomment OkDisplay, userCreated, setUserCreated, okNotificationRef when lab has to pay subscription
import { Formik, Form, Field } from "formik";
import { LabUserResponse, useUserService } from "../../services/users";
import { useEffect, useRef, useState } from "react";
import { LabSignUpFormData } from "../../lib/types/labs";
import { getIndustryRoles } from "../../services/generalInfo";
import { getRegions } from "../../services/regions";
import { TGenericIdName, TProcessStates } from "../../lib/types/general";
import { usePillInput } from "../common/pillInput";
import { useRouteNavigation } from "../../lib/common/routes";
import { PositionsCheckList } from "../common/positions-check-list";
import * as Yup from "yup";
import { emailRegex, numberRegex } from "../../lib/common/validation";
import { Spinner } from "../sections/backoffice/common/spinner";

export const SignupLabForm = () => {
    const [generalError, setGeneralError] = useState<string | undefined>(
        undefined
    );
    // const [userCreated, setUserCreated] = useState(false);
    const [regions, setRegions] = useState<TGenericIdName[]>([]);
    const [positions, setPositions] = useState<TGenericIdName[]>([]);
    const { getRoute, navigateToRoute } = useRouteNavigation();
    const [progress, setProgress] = useState(0);
    const [currentCard, setCurrentCard] = useState(1);
    const totalCards = 4;
    // const okNotificationRef = useRef(null);
    const signUpRef = useRef<HTMLDivElement>(null);
    const [workingState, setWorkingState] = useState<TProcessStates>("idle");

    const hobbies = usePillInput([]);
    const interests = usePillInput([]);

    useEffect(() => {
        getRegions()
            .then((data) => {
                setRegions(data);
                return getIndustryRoles();
            })
            .then((data) => {
                setPositions(data);
            }); //TODO: get a better error management
    }, []);

    const { configureNewLab, signUpUser } = useUserService();

    // const formValidationHandler = (values: LabSignUpFormData): FormikErrors<LabSignUpFormData> => {
    //     const errors: any = {};
    // if (!values.email) {
    //     errors.email = 'Required';
    // } else if (
    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    // ) {
    //     errors.email = 'Invalid email address';
    // }
    // if (!values.password) errors.password = "Required";
    // if (values.password && (!values.password2nd || (values.password !== values.password2nd))) errors.password2nd = "Passwords must match";
    // if (!values.labName) errors.labName = "Required";
    // if (!values.zip) errors.zip = "Required";
    // if (!values.contactName) errors.contactName = "Required";
    // if (!getPositions(values).length) errors.position_0 = "At least one is required";
    // if (!values.desiredRegion) errors.desiredRegion = "Required";
    // if (!values.accept) errors.accept = "Don't forget to agree to the terms and conditions.";

    //     return errors;
    // };
    const validationSchemas = [
        // Slide 1: Lab Data & TC
        Yup.object().shape({
            labName: Yup.string().required("Required"),
            contactName: Yup.string().required("Required"),
            accept: Yup.boolean().oneOf(
                [true],
                "Please accept the terms and conditions"
            ),
        }),
        // Slide 2: Region
        Yup.object().shape({
            zip: Yup.string()
                .matches(numberRegex, "ZIP must be a number")
                .required("Required"),
            desiredRegion: Yup.string().required("Required"),
        }),
        // Slide 3: Positions
        Yup.object().shape({
            yearsInBusiness: Yup.number()
                .nullable()
                .typeError("Must be a number")
                .integer("Must be a whole number")
                .min(0, "Must be a positive number")
                .max(299, "Can not be more than 299 years"),
            positions: Yup.array()
                .of(Yup.string())
                .min(1, "At least one position is required")
                .required("At least one position is required"),
        }),
        // Slide 4: Account Information
        Yup.object().shape({
            email: Yup.string()
                .required("Required")
                .matches(emailRegex, "Invalid email format"),
            password: Yup.string().required("Required"),
            password2nd: Yup.string()
                .oneOf([Yup.ref("password"), undefined], "Passwords must match")
                .required("Required"),
        }),
    ];

    const userValidationHandler = async (
        values: LabSignUpFormData
    ): Promise<LabUserResponse> => {
        const signUpResponse = await signUpUser(values.email, values.password);
        if (signUpResponse.error) {
            setWorkingState("error");
            window.scrollTo({ top: 0 });
            setGeneralError(signUpResponse.error);
        }
        return signUpResponse;
    };

    const submitHandler = (
        values: LabSignUpFormData,
        { setSubmitting }: { setSubmitting: (val: boolean) => void }
    ) => {
        setGeneralError(undefined);
        setTimeout(async () => {
            setWorkingState("working");
            const signUpResponse = await userValidationHandler(values);
            if (signUpResponse.error) {
                setSubmitting(false);
                return;
            }

            await configureNewLab({
                ...values,
                hobbies: hobbies.values,
                interests: interests.values,
                uid: signUpResponse.uid,
                positions: values.positions,
                password2nd: undefined,
            });
            // TODO: Uncomment setUserCreated when lab has to pay subscription
            // setUserCreated(true);
            localStorage.setItem("firstLogin", "true");
            setWorkingState("done");
            setTimeout(() => {
                navigateToRoute("dentalLabAccount");
                // TODO: Uncomment okNotificationRef when lab has to pay subscription
                // (okNotificationRef.current as any).scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                // });
                // (okNotificationRef.current as any).style.opacity = "1";
            }, 100);

            setSubmitting(false);
        }, 400);
    };
    // TODO: Uncomment OkDisplay when lab has to pay subscription
    // const OkDisplay = () => {
    //     const { navigateToRoute, navigateRouteClickHandler } =
    //         useRouteNavigation();

    //     setTimeout(() => {
    //         navigateToRoute("subscriptionSelection");
    //     }, 3000);

    //     return (
    //         <div
    //             ref={okNotificationRef}
    //             className="text-center"
    //             style={{ transition: "all 0.5s linear 0s", opacity: "0" }}
    //         >
    //             <i
    //                 className="fa-regular fa-circle-check text-success mb-4"
    //                 style={{ fontSize: "5rem" }}
    //             ></i>
    //             <h3 className="mb-4">🎉 Registration Successful!</h3>
    //             <a
    //                 className="text-info fs-5 fw-semibold"
    //                 href={getRoute("subscriptionSelection")}
    //                 onClick={(e) =>
    //                     navigateRouteClickHandler("subscriptionSelection", e)
    //                 }
    //             >
    //                 Let's Activate Your Plan ➡
    //             </a>
    //         </div>
    //     );
    // };

    const progressAction = async (
        direction: "next" | "prev",
        validateForm?: any
    ) => {
        const errors = await validateForm();
        if (direction === "next" && currentCard < totalCards) {
            if (Object.keys(errors).length === 0) {
                const newCard = currentCard + 1;
                setCurrentCard(newCard);
                setProgress((newCard / totalCards) * 100);
            }
        } else if (direction === "prev" && currentCard > 1) {
            const newCard = currentCard - 1;
            setCurrentCard(newCard);
            setProgress((newCard / totalCards) * 100);
        }

        signUpRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <div ref={signUpRef}>
            {/* TODO: Uncomment when lab has to pay subscription see closing brackets too.
            {userCreated ? (
                <OkDisplay />
            ) : ( */}
            <Formik
                initialValues={{
                    uid: "",
                    email: "",
                    password: "",
                    password2nd: "",
                    contactName: "",
                    labName: "",
                    zip: "",
                    yearsInBusiness: null,
                    desiredRegion: "",
                    positions: [],
                    accept: false,
                }}
                validationSchema={validationSchemas[currentCard - 1]}
                onSubmit={submitHandler}
            >
                {({
                    isSubmitting,
                    values,
                    handleChange,
                    validateForm,
                    errors,
                }) => (
                    <Form>
                        <div className="progress my-3 mx-auto signup-width px-0">
                            <div
                                className="progress-bar rounded"
                                style={{ width: `${progress}%` }}
                                role="progressbar"
                                aria-label="Lab Sign Up Progress"
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                                {progress}%
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div
                                id="labSignUpCarousel"
                                data-touch="false"
                                data-interval="false"
                                className="carousel carousel-dark slide signup-width"
                            >
                                <div className="carousel-inner">
                                    {validationSchemas.map(
                                        (validationSchema, index) => (
                                            <div
                                                key={index}
                                                className={`carousel-item ${
                                                    currentCard === index + 1
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <div className="card py-3">
                                                    {index === 0 && (
                                                        <>
                                                            <h5 className="text-center py-3">
                                                                👋 Welcome to
                                                                WinWinGo!
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                {" "}
                                                                We're excited to
                                                                have your lab
                                                                join our
                                                                community!
                                                                <br />
                                                                Let's start with
                                                                some basic
                                                                information.{" "}
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="Lab Name"
                                                                            >
                                                                                Lab
                                                                                Name
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-4 d-block d-md-none">
                                                                        {errors.labName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.labName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Lab Name"
                                                                            name="labName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                50
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.labName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.labName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                htmlFor="Contact Name"
                                                                                className="form-label"
                                                                            >
                                                                                Contact
                                                                                Name
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-4 d-block d-md-none">
                                                                        {errors.contactName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.contactName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Contact Name"
                                                                            name="contactName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                50
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.contactName ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.contactName
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row pt-4">
                                                                    <div className="col-12 d-flex align-items-center">
                                                                        <div className="form-check">
                                                                            <input
                                                                                name="accept"
                                                                                id="accept_0"
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                    values.accept
                                                                                }
                                                                                onChange={
                                                                                    handleChange
                                                                                }
                                                                                aria-describedby="acceptHelpBlock"
                                                                            />
                                                                            <label className="h6 form-label">
                                                                                I
                                                                                accept
                                                                                the
                                                                                <a
                                                                                    className="text-primary"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    href={getRoute(
                                                                                        "privacyPolicy"
                                                                                    )}
                                                                                >
                                                                                    <strong>
                                                                                        {" "}
                                                                                        Privacy
                                                                                        Policy{" "}
                                                                                    </strong>
                                                                                </a>
                                                                                and
                                                                                <a
                                                                                    className="text-primary"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    href={getRoute(
                                                                                        "termsOfUse"
                                                                                    )}
                                                                                >
                                                                                    <strong>
                                                                                        {" "}
                                                                                        Terms
                                                                                        of
                                                                                        Use
                                                                                    </strong>
                                                                                </a>

                                                                                .
                                                                                *
                                                                            </label>
                                                                        </div>
                                                                        <span
                                                                            id="acceptHelpBlock"
                                                                            className="form-text text-muted"
                                                                        ></span>
                                                                    </div>
                                                                    <div className="col mt-2">
                                                                        {errors.accept ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.accept
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 1 && (
                                                        <>
                                                            <h5 className="text-center py-3">
                                                                🌎 Where Is Your
                                                                Lab Located?
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Help us pinpoint
                                                                your lab's
                                                                location.
                                                                <br />
                                                                Please provide
                                                                your lab's zip
                                                                code and the
                                                                region where it
                                                                operates.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-5 col-md-3">
                                                                        <h6 className="mb-0">
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="Zip"
                                                                            >
                                                                                ZIP
                                                                                Code
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-7 d-block d-md-none mb-2 mb-md-0">
                                                                        {errors.zip ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.zip
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-5 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Zip"
                                                                            name="zip"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                10
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4 d-none d-md-block">
                                                                        {errors.zip ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.zip
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-12 mb-2 mb-md-0">
                                                                        <label className="h6 form-label me-3">
                                                                            What
                                                                            Region
                                                                            is
                                                                            the
                                                                            Lab
                                                                            located
                                                                            in?
                                                                            *
                                                                        </label>
                                                                        {errors.desiredRegion ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.desiredRegion
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    {regions.map(
                                                                        (
                                                                            region,
                                                                            index
                                                                        ) => (
                                                                            <div className="col-md-4">
                                                                                <div className="form-check">
                                                                                    <Field
                                                                                        name="desiredRegion"
                                                                                        id={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        type="radio"
                                                                                        key={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        className="form-check-input"
                                                                                        value={
                                                                                            region.id
                                                                                        }
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={
                                                                                            "Desired_Region_" +
                                                                                            index
                                                                                        }
                                                                                        className="form-control-label ms-2"
                                                                                    >
                                                                                        {
                                                                                            region.name
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 2 && (
                                                        <>
                                                            <h5 className="text-center py-3">
                                                                💼 Your Lab's
                                                                Experience and
                                                                Needs
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                We'd like to
                                                                understand your
                                                                lab's staffing
                                                                needs.
                                                                <br />
                                                                Please let us
                                                                know how many
                                                                years you've
                                                                been in business
                                                                and the
                                                                positions you're
                                                                seeking.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-md-4 py-md-2">
                                                                        <h6>
                                                                            <label
                                                                                htmlFor="Years in Business"
                                                                                className="form-label"
                                                                            >
                                                                                Years
                                                                                in
                                                                                Business
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-md-4 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Years in Business"
                                                                            name="yearsInBusiness"
                                                                            type="text"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                3
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 mb-3">
                                                                        {errors.yearsInBusiness ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.yearsInBusiness
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-12">
                                                                        <label className="h6 form-label">
                                                                            Positions
                                                                            We're
                                                                            Seeking
                                                                            *
                                                                        </label>
                                                                        <p>
                                                                            <small>
                                                                                You
                                                                                will
                                                                                be
                                                                                able
                                                                                to
                                                                                manage,
                                                                                add,
                                                                                or
                                                                                delete
                                                                                positions
                                                                                in
                                                                                your
                                                                                profile
                                                                                later
                                                                                too.
                                                                            </small>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12 mb-3">
                                                                        {errors.positions ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.positions
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <PositionsCheckList
                                                                        positions={
                                                                            positions
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {index === 3 && (
                                                        <>
                                                            <h5 className="text-center p-3">
                                                                Almost Done! 🎉
                                                            </h5>
                                                            <p className="text-center px-3">
                                                                Let's choose
                                                                your email and a
                                                                secure password.
                                                            </p>
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="Email"
                                                                            >
                                                                                Email
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-6 d-block d-md-none">
                                                                        {errors.email ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.email
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="Email"
                                                                            name="email"
                                                                            type="email"
                                                                            className="form-control"
                                                                            maxLength={
                                                                                254
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.email ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.email
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="password"
                                                                            >
                                                                                Password
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-4 d-block d-md-none">
                                                                        {errors.password ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.password
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="password"
                                                                            name="password"
                                                                            type="password"
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.password ? (
                                                                            <div className="badge rounded-pill text-bg-warning">
                                                                                {
                                                                                    errors.password
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-4">
                                                                    <div className="col-6 col-md-3">
                                                                        <h6>
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="password"
                                                                            >
                                                                                Confirm
                                                                                Password
                                                                                *
                                                                            </label>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="col-6 d-block d-md-none">
                                                                        {errors.password2nd ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.password2nd
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-md-6 mb-2 mb-md-0">
                                                                        <Field
                                                                            id="password2nd"
                                                                            name="password2nd"
                                                                            type="password"
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block">
                                                                        {errors.password2nd ? (
                                                                            <div className="badge rounded-pill text-bg-warning text-wrap">
                                                                                {
                                                                                    errors.password2nd
                                                                                }
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-1 mb-4">
                                                                    {generalError ? (
                                                                        <div className="badge rounded-pill text-bg-danger text-wrap">
                                                                            {
                                                                                generalError
                                                                            }
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="rounded shadow mb-5 text-center ai-container p-3">
                                                                    <p>
                                                                        <strong>
                                                                            {" "}
                                                                            ⭐
                                                                            You're
                                                                            about
                                                                            to
                                                                            join
                                                                            the
                                                                            beta
                                                                            version
                                                                            of
                                                                            our
                                                                            platform!
                                                                            ⭐
                                                                        </strong>
                                                                        <br />{" "}
                                                                        <br />
                                                                        <strong className="text-secondary fs-1">
                                                                            FREE
                                                                            FOR
                                                                            A
                                                                            LIMITED
                                                                            TIME
                                                                            ONLY
                                                                        </strong>
                                                                        <br />{" "}
                                                                        <br />
                                                                        This
                                                                        beta
                                                                        version
                                                                        is{" "}
                                                                        <strong>
                                                                            FREE
                                                                            FOR
                                                                            A
                                                                            LIMITED
                                                                            TIME
                                                                        </strong>
                                                                        , so
                                                                        take
                                                                        advantage
                                                                        and
                                                                        explore
                                                                        all the
                                                                        features!
                                                                        <br />
                                                                    </p>
                                                                </div>
                                                                <div className=" lh-lg">
                                                                    <h6>
                                                                        <i className="fa-solid fa-arrow-right me-2"></i>
                                                                        Next
                                                                        Step:{" "}
                                                                    </h6>
                                                                    <p>
                                                                        After
                                                                        signing
                                                                        up,
                                                                        you’ll
                                                                        be taken
                                                                        to your
                                                                        profile,
                                                                        where
                                                                        you can
                                                                        add your
                                                                        lab’s
                                                                        logo and
                                                                        essential
                                                                        details.
                                                                        <br />
                                                                        <strong>
                                                                            Make
                                                                            your
                                                                            lab
                                                                            stand
                                                                            out
                                                                            and
                                                                            connect
                                                                            with
                                                                            skilled
                                                                            techs
                                                                            today!
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row gap-4 justify-content-center mt-5">
                            {currentCard > 1 && (
                                <button
                                    className="btn btn-outline-dark col-md-4 col-lg-3"
                                    type="button"
                                    onClick={() =>
                                        progressAction("prev", validateForm)
                                    }
                                >
                                    <span className="" aria-hidden="true">
                                        Back
                                    </span>
                                    <span className="visually-hidden">
                                        Previous
                                    </span>
                                </button>
                            )}
                            {currentCard < totalCards && (
                                <button
                                    className="btn btn-primary col-md-7 col-lg-5"
                                    type="button"
                                    onClick={() =>
                                        progressAction("next", validateForm)
                                    }
                                >
                                    <span className="" aria-hidden="true">
                                        Next
                                    </span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            )}
                            {currentCard === totalCards && (
                                <button
                                    disabled={isSubmitting}
                                    name="submit"
                                    type="submit"
                                    className="btn btn-primary col-md-7 col-lg-5"
                                >
                                    {workingState === "working" ? (
                                        <Spinner />
                                    ) : (
                                        "Start Free Beta Version Now"
                                    )}
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            {/* )} */}
        </div>
    );
};
