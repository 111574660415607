import { createContext, useContext, useEffect, useState } from "react";
import * as chatServices from "../services/chats";
import { useAuthContext } from "./auth-context";
import { TChatResponse } from "../lib/types/chats";
import { TUserInfo } from "../lib/types/users";

export type TWSReadNotificationData = {
    fromDate: string;
    toDate: string;
    conversationId: string;
};
export type TGetChats = (
    withUserId: string,
    lastMessageDateNoTZ?: string
) => Promise<TChatResponse>;
export type TSendChat = (
    withUserId: string,
    conversationId: string
) => Promise<any>;
export type TUserToChatInfo = {
    id: string;
    name: string;
};
type TReadNotificationsMessageHandler = (p: TWSReadNotificationData) => void;
type TChatState = "expanded" | "minimized";
export type TUseServiceContext = {
    general: {
        logOut: () => void;
    };
    notifications: {
        addNewMessagesHandler: (fn: (() => void) | undefined) => void;
        removeMessageHandler: (fn: () => void) => void;
        setNewConversationMessageHandler: (fn?: () => void) => void;
        setReadNotificationMessageHandler: (
            fn?: TReadNotificationsMessageHandler
        ) => void;
    };
    chats: {
        getChats: TGetChats;
        sendMessage: TSendChat;
        startConversation: TGetChats;
        endConversation: TGetChats;
        getMessages: TGetChats;
        showChat: boolean;
        setShowChat: (v: boolean) => void;
        chatState: TChatState;
        setChatState: (f: (v: TChatState) => TChatState) => void;
        userIdToChat: TUserToChatInfo;
        setUserIdToChat: (v: TUserToChatInfo) => void;
        removeSocket: () => void;
    };
};

declare global {
    interface Window {
        wwgWS: WebSocket | undefined;
    }
}

window.wwgWS = undefined;

const removeSocket = () => {
    if (window.wwgWS) {
        const ws = window.wwgWS as WebSocket;
        if (ws.readyState === 0) {
            setTimeout(() => {
                removeSocket();
            }, 500);
        }

        if (ws.readyState === 1) {
            ws.close();
            delete window.wwgWS;
        }
    }
};

export const useService = (): TUseServiceContext => {
    const { getUserTokenId, userInfo, logOutUser } = useAuthContext();
    const [showChat, setShowChat] = useState<boolean>(false);
    const [chatState, setChatState] = useState<TChatState>("expanded");
    const [userIdToChat, setUserIdToChat] = useState<TUserToChatInfo>({
        id: "",
        name: "",
    });
    const [newMessagesHandler, _setNewMessagesHandler] =
        useState<(() => void)[]>();
    const addNewMessagesHandler = (fn: (() => void) | undefined) => {
        _setNewMessagesHandler((old) => {
            const newList = old ? [...old] : [];
            if (fn) {
                newList.push(fn);
                return newList;
            }
            return old;
        });
    };

    const removeMessageHandler = (fn: () => void) => {
        _setNewMessagesHandler((old) => {
            if (!old) return old;
            const handlerIndex = old.findIndex((f) => f === fn);

            if (handlerIndex === -1) return old;
            old.splice(handlerIndex, 1);
            const newList = [...old];
            return newList;
        });
    };
    const [newConversationMessageHandler, setNewConversationMessageHandler] =
        useState<() => void>();
    const [readNotificationMessageHandler, setReadNotificationMessageHandler] =
        useState<TReadNotificationsMessageHandler>();

    const [messageFromSocket, setNewMessageFromSocket] = useState<{
        message: string;
        data: any;
    }>({ message: "none", data: "" });

    const SOCKET_EVENTS = {
        newMessages: "newMessages",
        newConversationMessages: "newConversationMessages",
        readNotification: "readNotification",
    };

    const logOut = () => {
        setShowChat(false);
        setUserIdToChat({ id: "", name: "" });
        logOutUser();
    };

    const _createSocket = (userInfo: TUserInfo) => {
        const mainDomain =
            process.env.REACT_APP_WS_ENDPOINT === "host"
                ? `wss://${window.location.host}`
                : process.env.REACT_APP_WS_ENDPOINT || "";
        const url = `${mainDomain}?userId=${userInfo.id}&userType=${userInfo.type}`;
        if (window.wwgWS === undefined) {
            window.wwgWS = new WebSocket(url);
            const ws = window.wwgWS;
            if (ws !== undefined && ws !== null) {
                ws.onmessage = (event) => {
                    const eventInfo = event.data.split("::");
                    switch (eventInfo[0]) {
                        case SOCKET_EVENTS.newMessages:
                            setNewMessageFromSocket({
                                message: eventInfo[0],
                                data: undefined,
                            });
                            break;
                        case SOCKET_EVENTS.newConversationMessages:
                            setNewMessageFromSocket({
                                message: eventInfo[0],
                                data: eventInfo[1],
                            });
                            break;
                        case SOCKET_EVENTS.readNotification:
                            setNewMessageFromSocket({
                                message: eventInfo[0],
                                data: JSON.parse(
                                    decodeURIComponent(eventInfo[1])
                                ),
                            });
                            break;
                        default:
                            break;
                    }
                };
                const wsKeepAlive = () => {
                    if (window.wwgWS) {
                        if (window.wwgWS.readyState === 1) {
                            window.wwgWS.send("ping");
                        }
                        setTimeout(() => {
                            wsKeepAlive();
                        }, 7000);
                    }
                };
                ws.onclose = async () => {
                    removeSocket();
                };
                wsKeepAlive();
            }
        }
    };

    useEffect(() => {
        const eventInfo = messageFromSocket.message;

        switch (eventInfo) {
            case SOCKET_EVENTS.newMessages:
                if (newMessagesHandler) newMessagesHandler.forEach((f) => f());
                break;
            case SOCKET_EVENTS.newConversationMessages:
                if (newConversationMessageHandler)
                    newConversationMessageHandler();
                break;
            case SOCKET_EVENTS.readNotification:
                if (readNotificationMessageHandler)
                    readNotificationMessageHandler(
                        messageFromSocket.data as TWSReadNotificationData
                    );
                break;
            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageFromSocket]);

    if (userInfo && userInfo.id) {
        _createSocket(userInfo);
    }

    const getChats: TGetChats = async (withUserId: string) => {
        return await chatServices.getConversations(
            await getUserTokenId(),
            withUserId
        );
    };

    const sendMessage: TSendChat = async (
        content: string,
        conversationId: string
    ) => {
        return await chatServices.sendMessage(
            content,
            await getUserTokenId(),
            conversationId
        );
    };

    const getMessages: TGetChats = async (
        withUserId: string,
        lastMessageDateNoTZ?: string
    ) => {
        return await chatServices.getMessages(
            await getUserTokenId(),
            withUserId,
            lastMessageDateNoTZ
        );
    };

    const startConversation: TGetChats = async (withUserId: string) => {
        return await chatServices.startChatting(
            await getUserTokenId(),
            withUserId
        );
    };

    const endConversation = async (withUserId: string) => {
        return await chatServices.endChatting(
            await getUserTokenId(),
            withUserId
        );
    };

    /*    useEffect(() => {
            setTimeout(() => {
                checkMessages();
            }, 1000);
            // eslint-disable-next-line        
        }, [])
    */
    return {
        general: {
            logOut,
        },
        notifications: {
            addNewMessagesHandler,
            removeMessageHandler,
            setNewConversationMessageHandler: (v) =>
                setNewConversationMessageHandler((prev) => v),
            setReadNotificationMessageHandler: (v) =>
                setReadNotificationMessageHandler(
                    (prev?: TReadNotificationsMessageHandler) => v
                ),
        },
        chats: {
            chatState,
            setChatState,
            endConversation,
            getChats,
            getMessages,
            sendMessage,
            startConversation,
            showChat,
            setShowChat,
            userIdToChat,
            setUserIdToChat,
            removeSocket,
        },
    };
};

const ServiceContext = createContext<TUseServiceContext>({
    general: {
        logOut: () => {},
    },
    notifications: {
        removeMessageHandler: (v: () => void) => {},
        addNewMessagesHandler: (v: (() => void) | undefined) => {},
        setNewConversationMessageHandler: (v?: () => void) => {},
        setReadNotificationMessageHandler: (
            v?: (p: TWSReadNotificationData) => void
        ) => {},
    },
    chats: {
        getChats: async (v: string): Promise<TChatResponse> => ({
            data: {
                messages: [],
                conversationId: "",
            },
        }),
        chatState: "expanded",
        setChatState: () => {},
        sendMessage: async (v: string, conversationId: string) => {},
        startConversation: async (v: string) => ({
            data: { messages: [], conversationId: "" },
        }),
        endConversation: async (v: string) => ({
            data: { messages: [], conversationId: "" },
        }),
        getMessages: async (v: string) => ({
            data: { messages: [], conversationId: "" },
        }),
        showChat: false,
        setShowChat: (v: boolean) => {},
        userIdToChat: { id: "", name: "" },
        setUserIdToChat: (v: TUserToChatInfo) => {},
        removeSocket,
    },
});

export const useServiceContext = () => {
    return useContext(ServiceContext);
};

export const ServiceProvider = (props: any) => {
    const value: TUseServiceContext = useService();

    return (
        <ServiceContext.Provider value={value}>
            {props.children}
        </ServiceContext.Provider>
    );
};
