export const spinner = (
    <>
        <span className="fa-solid fa-spinner fa-spin text-white"></span>
    </>
);

export const Spinner = ({
    spinnerColor = "text-white",
}: {
    spinnerColor?: string;
}) => {
    return (
        <span className={`fa-solid fa-spinner fa-spin ${spinnerColor}`}></span>
    );
};
