import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";

type TPricingData = {
    card_name?: string;
    title: string;
    price: string;
    features: string[];
    duration?: string;
    sign_up_form: RouteEnumType;
    tag?: string;
};

const pricingData: TPricingData[] = [
    {
        card_name: "Tech",
        title: "Techs Are Always Free",
        price: "Free",
        features: [
            "Unlimited job opportunities",
            "Access to all lab postings",
            "Direct applications to top dental labs",
            "Powered AI suggestions based on hobbies, interests, and skills",
        ],
        sign_up_form: "signupTech",
        tag: "Free!",
    },
    {
        card_name: "Lab",
        title: "Labs Monthly Subscription",
        price: "Beta Access: Free",
        duration: "Regular Price: $299 / month",
        features: [
            "Direct communication between labs and techs",
            "AI tech/lab matching feature",
            "Target techs interested in your area",
            "Dental industry-specific features",
        ],
        sign_up_form: "signupLab",
        tag: "🚀 Beta Access: Free for limited time only!",
    },
];

export const PricingBlade = () => {
    const { navigateRouteClickHandler } = useRouteNavigation();

    return (
        <div className="container-fluid bg-light pricing-bg">
            <div className="container">
                <div className="row justify-content-center py-5 p-lg-5">
                    <h1 className="home-title-h1">Simple Pricing</h1>
                    <h4 className="mb-3 lh-lg">
                        <strong>Techs </strong>are always free! <br />
                        <strong>Labs </strong>get premium features for
                        $299/month 🚀 Beta Access: Free for a limited time!
                    </h4>

                    <div className="gap-5 card-group">
                        {pricingData.map((plan, index) => (
                            <div className="card shadow border-0 rounded">
                                <div className="pb-3">
                                    <h4 className="text-end mt-3">
                                        {" "}
                                        <span
                                            className={`badge rounded-0 rounded-start px-4 ms-4 text-wrap shadow  ${
                                                plan.card_name === "Lab"
                                                    ? "text-bg-warning"
                                                    : "text-bg-dark"
                                            }`}
                                        >
                                            {plan.tag}
                                        </span>
                                    </h4>
                                </div>
                                <div className="px-4 px-md-5 pb-3 card-body">
                                    <h2 className="text-info fw-bold highlighted-title pb-3">
                                        {plan.card_name} Subscription
                                    </h2>
                                    <h1 className="fw-bold">{plan.price}</h1>
                                    <h6 className="fw-semibold">
                                        {plan.duration}
                                    </h6>
                                    <div style={{ minHeight: "9rem" }}>
                                        <ul className="mt-4">
                                            {plan.features.map((feature, i) => (
                                                <li key={i}>{feature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-footer d-lg-flex d-inline-grid gap-md-3 gap-4 px-md-5 py-4 bg-white rounded-bottom border-0">
                                    <button
                                        className="btn btn-info w-100 fw-bold"
                                        onClick={(e) =>
                                            navigateRouteClickHandler(
                                                "pricing",
                                                e
                                            )
                                        }
                                    >
                                        See more
                                    </button>
                                    <button
                                        className="btn btn-info w-100 fw-bold"
                                        onClick={(e) =>
                                            navigateRouteClickHandler(
                                                plan.sign_up_form,
                                                e
                                            )
                                        }
                                    >
                                        {plan.card_name} Sign Up
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
