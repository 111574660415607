import { useEffect, useRef } from "react";
import { SUPPORT_EMAIL } from "../../lib/common/constants";

export const SupportBtn = () => {
    const refSupportModal = useRef<HTMLDivElement>(null);
    const windowGlobal = window as any;

    const handleFirstModalShow = () => {
        const showModal1 = () => {
            if (!refSupportModal.current) {
                setTimeout(() => {
                    showModal1();
                }, 100);
                return;
            }

            windowGlobal.wwg_refSupportModal = new window.bootstrap.Modal(
                refSupportModal.current
            );

            windowGlobal.wwg_refSupportModal.show();
        };

        showModal1();
    };
    const handleFirstModalClose = () => {
        if (!refSupportModal.current) {
            setTimeout(() => {
                handleFirstModalClose();
            }, 100);
            return;
        }
        if (windowGlobal.wwg_refSupportModal) {
            //TODO: change to window.bootstrap.Modal.getInstance(refSupportModal.current)?.hide(); when we update to bootstrap 5.2
            windowGlobal.wwg_refSupportModal.hide();
        }
    };

    useEffect(() => {
        return () => {
            if (windowGlobal.wwg_refSupportModal) {
                windowGlobal.wwg_refSupportModal.hide();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <button
                type="button"
                className="btn mt-1"
                data-bs-toggle="modal"
                onClick={handleFirstModalShow}
            >
                <i className="fa-regular fa-circle-question text-white fs-5"></i>
            </button>

            <div className="modal fade" tabIndex={-1} ref={refSupportModal}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-primary">
                            <h1 className="modal-title fs-5">
                                {" "}
                                <i className="fa-regular fa-circle-question me-3 fs-lg"></i>
                                Contact Support
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                // data-bs-dismiss="modal"
                                onClick={handleFirstModalClose}
                            ></button>
                        </div>
                        <div className="modal-body p-5">
                            <p className="fw-bold">
                                Need help? <br />
                                Send us an email, and we’ll get back to you as
                                soon as possible!
                            </p>
                            <a
                                className="btn-link text-primary fw-bold"
                                href={"mailto:" + SUPPORT_EMAIL}
                            >
                                {SUPPORT_EMAIL}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
